import React from "react";
import StoreList from "./StoreList";
import airports from "./airports"; // Make sure this points to your stores data
import "./AirportsPage.css";

function AirportsPage() {
  return (
    <div>
      <h2 className="naslov">Airports Working Times</h2>
      <StoreList businesses={airports} />
    </div>
  );
}

export default AirportsPage;
