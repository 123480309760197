import React from "react";
import StoreList from "./StoreList";
import banks from "./banks"; // Make sure this points to your stores data

function BanksPage() {
  return (
    <div>
      <h2 className="naslov">Banks Working Times</h2>
      <StoreList businesses={banks} />
    </div>
  );
}

export default BanksPage;
