import React, { useState, useRef, useEffect } from "react";
import "./StoreList.css";

function StoreList({ businesses }) {
  const [selectedBusinessIndex, setSelectedBusinessIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const detailsRef = useRef(null);

  const getCurrentDay = () => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const today = new Date();
    return days[today.getDay()];
  };

  const isOpen = (business) => {
    const currentDay = getCurrentDay();
    const hours = business.hours[currentDay];

    if (hours === "Closed") return false;

    const [open, close] = hours.split("-");
    const currentTime = new Date().toTimeString().slice(0, 5);

    return currentTime >= open && currentTime <= close;
  };

  const filteredBusinesses = businesses.filter((business) =>
    business.name.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  const sortedBusinesses = filteredBusinesses.sort((a, b) => {
    if (isOpen(a) && !isOpen(b)) return -1;
    if (!isOpen(a) && isOpen(b)) return 1;
    return a.name.localeCompare(b.name);
  });

  const handleBusinessClick = (index) => {
    if (selectedBusinessIndex === index) {
      setSelectedBusinessIndex(null);
    } else {
      setSelectedBusinessIndex(index);
    }
  };

  useEffect(() => {
    if (selectedBusinessIndex !== null && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedBusinessIndex]);

  return (
    <div>
      <h2 className="pretraga">
        <input
          type="text"
          className="search-input"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </h2>
      <table className="store-table">
        <thead>
          <tr>
            <th>Business Name</th>
          </tr>
        </thead>
        <tbody>
          {sortedBusinesses.map((business, index) => (
            <React.Fragment key={index}>
              <tr
                className={`store-row ${isOpen(business) ? "open" : "closed"}`}
                onClick={() => handleBusinessClick(index)}
              >
                <td>{business.name}</td>
              </tr>
              {selectedBusinessIndex === index && (
                <tr className="store-details-row" ref={detailsRef}>
                  <td colSpan="1">
                    <div className="store-details">
                      <h3>Working Hours</h3>
                      <ul>
                        {Object.keys(business.hours).map((day) => (
                          <li key={day}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}:{" "}
                            {business.hours[day]}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default StoreList;
