import React from "react";

function Redvoznje() {
  return (
    <div className="redvoznje-page">
      <h2>GRADSKA AUTOBUSNA LINIJA</h2>

      <div className="bus-schedule">
        <h3>
          TRPINJSKA CESTA - BOROVO NASELJE - CENTAR - BOROVO NASELJE - TRPINJSKA
          CESTA
        </h3>
        <table className="bus-table">
          <thead>
            <tr>
              <th>Stanica</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lipovača</td>
              <td></td>
              <td></td>
              <td></td>
              <td>6:00</td>
              <td></td>
              <td>7:20</td>
              <td></td>
              <td>8:40</td>
              <td></td>
              <td>10:10</td>
              <td></td>
              <td></td>
              <td>12:25</td>
              <td>13:15</td>
              <td></td>
              <td>15:45</td>
              <td>17:05</td>
              <td></td>
              <td>20:05</td>
            </tr>
            <tr>
              <td>Trpinjska cesta</td>
              <td></td>
              <td>5:15</td>
              <td></td>
              <td>6:05</td>
              <td>6:40</td>
              <td>7:25</td>
              <td>8:10</td>
              <td>8:55</td>
              <td>9:40</td>
              <td>10:25</td>
              <td>11:10</td>
              <td>11:55</td>
              <td>12:30</td>
              <td>13:25</td>
              <td>14:35</td>
              <td>15:50</td>
              <td>17:20</td>
              <td>18:50</td>
              <td>20:10</td>
            </tr>
            <tr>
              <td>Borovo N.</td>
              <td></td>
              <td>5:25</td>
              <td></td>
              <td>6:15</td>
              <td>6:50</td>
              <td>7:35</td>
              <td>8:20</td>
              <td>9:05</td>
              <td>9:50</td>
              <td>10:35</td>
              <td>11:20</td>
              <td>12:05</td>
              <td>12:40</td>
              <td>13:35</td>
              <td>14:45</td>
              <td>16:00</td>
              <td>17:30</td>
              <td>19:05</td>
              <td>20:20</td>
            </tr>
            <tr>
              <td>Budžak</td>
              <td></td>
              <td>5:27</td>
              <td></td>
              <td>6:17</td>
              <td>6:52</td>
              <td>7:37</td>
              <td>8:22</td>
              <td>9:07</td>
              <td>9:52</td>
              <td>10:37</td>
              <td>11:22</td>
              <td>12:07</td>
              <td>12:42</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Centar</td>
              <td></td>
              <td>5:35</td>
              <td></td>
              <td>6:30</td>
              <td>7:05</td>
              <td>7:50</td>
              <td>8:35</td>
              <td>9:20</td>
              <td>10:05</td>
              <td>10:50</td>
              <td>11:35</td>
              <td>12:20</td>
              <td>12:55</td>
              <td>13:50</td>
              <td>15:00</td>
              <td>16:15</td>
              <td>17:45</td>
              <td>19:20</td>
              <td>20:35</td>
            </tr>
            <tr>
              <td>Novo groblje</td>
              <td></td>
              <td>5:45</td>
              <td></td>
              <td>6:45</td>
              <td>7:20</td>
              <td>8:05</td>
              <td>8:50</td>
              <td>9:35</td>
              <td>10:20</td>
              <td>11:05</td>
              <td>11:50</td>
              <td>12:35</td>
              <td>13:05</td>
              <td>14:05</td>
              <td>15:10</td>
              <td>16:25</td>
              <td>17:55</td>
              <td>19:25</td>
              <td>20:50</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>N</th>
              <th>SN</th>
              <th></th>
              <th>N</th>
              <th></th>
              <th>N</th>
              <th></th>
              <th>N</th>
              <th></th>
              <th>N</th>
              <th></th>
              <th>N</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Novo groblje</td>
              <td>5:30</td>
              <td>6:00</td>
              <td>6:30</td>
              <td>6:45</td>
              <td>7:20</td>
              <td>8:05</td>
              <td>8:50</td>
              <td>9:35</td>
              <td>10:20</td>
              <td>11:05</td>
              <td>11:50</td>
              <td>12:35</td>
              <td>13:20</td>
              <td>14:05</td>
              <td>15:10</td>
              <td>16:30</td>
              <td>18:00</td>
              <td>19:30</td>
              <td>21:00</td>
            </tr>
            <tr>
              <td>Centar</td>
              <td>5:35</td>
              <td>6:10</td>
              <td>6:40</td>
              <td>6:55</td>
              <td>7:30</td>
              <td>9:00</td>
              <td>9:00</td>
              <td>9:45</td>
              <td>10:30</td>
              <td>11:15</td>
              <td>12:00</td>
              <td>12:45</td>
              <td>13:30</td>
              <td>14:15</td>
              <td>15:20</td>
              <td>16:40</td>
              <td>18:10</td>
              <td>19:40</td>
              <td>21:10</td>
            </tr>
            <tr>
              <td>Borovo N.</td>
              <td>5:50</td>
              <td>6:25</td>
              <td>6:55</td>
              <td>7:10</td>
              <td>7:45</td>
              <td>9:15</td>
              <td>9:15</td>
              <td>10:00</td>
              <td>10:45</td>
              <td>11:30</td>
              <td>12:15</td>
              <td>13:00</td>
              <td>13:45</td>
              <td>14:30</td>
              <td>15:35</td>
              <td>16:55</td>
              <td>18:25</td>
              <td>19:55</td>
              <td>21:25</td>
            </tr>
            <tr>
              <td>Lipovača</td>
              <td>6:00</td>
              <td></td>
              <td></td>
              <td>7:20</td>
              <td></td>
              <td></td>
              <td></td>
              <td>10:10</td>
              <td></td>
              <td></td>
              <td>12:25</td>
              <td>13:10</td>
              <td>13:55</td>
              <td></td>
              <td>15:45</td>
              <td>17:05</td>
              <td></td>
              <td>20:05</td>
              <td></td>
            </tr>
            <tr>
              <td>Trpinjska cesta</td>
              <td></td>
              <td>6:35</td>
              <td></td>
              <td></td>
              <td>7:55</td>
              <td>9:25</td>
              <td>9:25</td>
              <td></td>
              <td>10:55</td>
              <td>11:40</td>
              <td></td>
              <td></td>
              <td>14:05</td>
              <td>14:35</td>
              <td></td>
              <td></td>
              <td>18:35</td>
              <td></td>
              <td>21:30</td>
            </tr>
            <tr>
              <td>Novo groblje</td>
              <td></td>
              <td>5:45</td>
              <td></td>
              <td>6:45</td>
              <td>7:20</td>
              <td>8:05</td>
              <td>8:50</td>
              <td>9:35</td>
              <td>10:20</td>
              <td>11:05</td>
              <td>11:50</td>
              <td>12:35</td>
              <td>13:05</td>
              <td>14:05</td>
              <td>15:10</td>
              <td>16:25</td>
              <td>17:55</td>
              <td>19:25</td>
              <td>20:50</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Redvoznje;
