import React from "react";
import { Link } from "react-router-dom";
import "./WorkingTimes.css";

function WorkingTimes() {
  return (
    <div className="tabelaradnogvremena">
      <h2>Working Times</h2>
      <ul className="lista">
        <li className="prodavnice">
          <Link to="/working-times/stores">Stores</Link>
        </li>
        <li className="prodavnice">
          <Link to="/working-times/banks">Banks</Link>
        </li>
        <li className="prodavnice">
          <Link to="/working-times/airports">Airports</Link>
        </li>
        {/* Add more categories as needed */}
      </ul>
    </div>
  );
}

export default WorkingTimes;
