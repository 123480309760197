const banks = [
  {
    name: "Bank A",
    hours: {
      monday: "09:00-17:00",
      tuesday: "09:00-17:00",
      wednesday: "09:00-17:00",
      thursday: "09:00-17:00",
      friday: "09:00-17:00",
      saturday: "Closed",
      sunday: "Closed",
    },
  },
  // Add more banks as needed
];

export default banks;
