import React from "react";
import StoreList from "./StoreList";
import stores from "./stores"; // Make sure this points to your stores data

function StoresPage() {
  return (
    <div>
      <h2 className="naslov">Stores Working Times</h2>
      <StoreList businesses={stores} />
    </div>
  );
}

export default StoresPage;
