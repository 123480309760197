import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Redvoznje from "./Redvoznje";
import WorkingTimes from "./WorkingTimes";
import StoresPage from "./StoresPage";
import BanksPage from "./BanksPage";
import AirportsPage from "./AirportsPage";
import Footer from "./Footer";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/redvoznje" element={<Redvoznje />} />
          <Route path="/working-times" element={<WorkingTimes />} />
          <Route path="/working-times/stores" element={<StoresPage />} />
          <Route path="/working-times/banks" element={<BanksPage />} />
          <Route path="/working-times/airports" element={<AirportsPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
