import React from "react";
import Weather from "./Weather";

function Home() {
  return (
    <div>
      <h1>Welcome</h1>
      <Weather city="Vukovar" /> {""}
    </div>
  );
}

export default Home;
