const stores = [
  {
    name: "Store A",
    hours: {
      monday: "08:00-17:00",
      tuesday: "08:00-17:00",
      wednesday: "08:00-17:00",
      thursday: "08:00-17:00",
      friday: "08:00-17:00",
      saturday: "10:00-14:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store B",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store C",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store D",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store E",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "10:00-19:00",
    },
  },
  {
    name: "Store F",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store G",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store H",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store J",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-20:00",
      sunday: "10:00-19:00",
    },
  },
  {
    name: "Store K",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-16:00",
      sunday: "Closed",
    },
  },
  {
    name: "Store L",
    hours: {
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "10:00-19:00",
      sunday: "10:00-19:00",
    },
  },
  // Add more stores as needed
];

export default stores;
