import React from "react";

function Services() {
  return (
    <div>
      <h2>Services</h2>
    </div>
  );
}

export default Services;
